<style lang="scss">
footer {
  background: #fff;
  padding-top: 50px;
  padding-bottom: 0px;
  margin-left: 350px;
  background: #f9f9f9;
  position: relative;
  /*&:before {
    content:"";
    display:block;
    width:100%;
    height:1px;
    position:absolute;
    top:-5px;
    background:#eee;

  }*/
  @media (max-width: 1200px) {
    margin-left: 0;
  }
  [class*='col-'] {
    border: 0px solid blue;
  }
  .sub-navi-headline {
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    @media (max-width: 1200px) {
      text-align: center;
    }
    @media (max-width: 992px) {
      margin-top: 20px;
    }
    &:after {
      content: '';
      display: block;
      width: 50px;
      height: 3px;
      background: $blue-darker;
      margin: 10px 0;
      @media (max-width: 1200px) {
        margin: 10px auto;
      }
    }
  }
  .sub-navi {
    @media (max-width: 1200px) {
      text-align: center;
    }
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      li {
        margin: 5px 0;
        a,
        .modal-button {
          display: block;
          font-size: 90%;
          color: $font-color;
          &:hover {
            color: $blue !important;
          }
        }
        .router-link-active {
          border-left: 0;
          color: $blue !important;
          &:after {
            display: none !important;
          }
        }
      }
    }
  }
  .info-box-mail {
    text-align: center;
    @media (max-width: 1200px) {
      margin-top: 40px;
    }
    .txt {
      display: block;
      margin-bottom: 10px;
      font-weight: 300;
    }
    a {
      display: block;
      position: relative;
      max-width: 90%;
      margin: 0 auto;

      .frontBg {
        display: block;
        padding: 15px 0;
        background: $blue-darker;
        color: #fff;
        position: relative;
        z-index: 3;
      }
      .backBg {
        display: block;
        width: 100%;
        height: 100%;
        background: $blue;
        position: absolute;
        left: 10px;
        top: 10px;
        z-index: 1;
      }
    }
    &:hover {
      .frontBg {
        background: $blue;
      }
      .backBg {
        background: $blue-darker;
      }
    }
  }

  .line-bottom {
    position: relative;
    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 20px;
    max-width: 90%;
    margin: 20px auto 0 auto;
    &:before {
      content: '';
      display: block;
      width: 98%;
      height: 1px;
      background: #eee;
      margin: 5px auto;
    }
    &:after {
      content: '';
      display: block;
      width: 98%;
      height: 1px;
      background: #ccc;
      margin: 5px auto;
    }
  }

  .socket {
    padding-bottom: 20px;
    span {
      display: block;
      font-size: 12px;
      text-align: center;
    }
  }
}

.smd-modal-container {
  .headline-modal {
    font-size: 150%;
    margin-bottom: 20px;
  }
  .impressum-content {
    h2 {
      padding-top: 0 !important;
    }
    a {
      color: $blue;
      &:hover {
        text-decoration: none;
        color: $blue-darker;
      }
    }
  }
}
</style>
<template>
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-xl-7 col-lg-12">
          <div class="row">
            <div class="col-lg-12">
              <div class="sub-navi-headline">Kleine Zeitung</div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="sub-navi">
                <ul>
                  <li><router-link :to="{ name: 'Allgemeine Informationen' }">Allgemeine Informationen</router-link></li>
                  <li><router-link :to="{ name: 'Technische Daten' }">Technische Daten</router-link></li>

                  <li><a href="../../pdf/allgemeines/KLZ-Fact-Sheet_Tarif_Oktober_2021.pdf" target="_blank">Mediadaten</a></li>
                  <li><a href="../../pdf/allgemeines/Tarif-2021.pdf" target="_blank">Tarif 2021 // PDF</a></li>
                  <!-- </ul>
                  <li><router-link :to="{ name: 'calculator' }">Tarif Rechner</router-link></li>
                  <li><a href="https://klz-verkauf.at/wp-content/uploads/vu-daten/Zielgruppenrechner/index.html" target="_blank">Zielgruppen-Rechner</a></li>
                  <li><ModalButton link="quellen">Quellen</ModalButton></li>
                  <li><ModalButton link="kontakt">Kontakt</ModalButton></li>-->
                </ul>
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="sub-navi">
                <ul>
                  <li><ModalButton link="impressum">Impressum</ModalButton></li>
                  <li><router-link :to="{ name: 'Kontakte' }">Kontakt</router-link></li>
                  <li><a href="../../pdf/allgemeines/AGB.pdf" target="_blank">Zahlungs und Geschäftsbedinungen</a></li>
                  <li><a href="https://www.kleinezeitung.at/service/unternehmen/impressum/5104679/Datenschutz_Datenschutzinformation-Werbemarkt" target="_blank">Datenschutzinformationen</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-5 justify-content-center align-self-center">
          <div class="info-box-mail">
            <div class="txt">Treten Sie mit uns in Kontakt</div>
            <a href="mailto:meinewerbung@kleinezeitung.at"><span class="frontBg">meinewerbung@kleinezeitung.at</span><span class="backBg"></span></a>
          </div>
        </div>
      </div>
    </div>
    <div class="line-bottom"></div>
    <div class="socket">
      <span>© 2020, kleinezeitung.at/tarif | Kleine Zeitung GmbH &amp; Co KG</span>
    </div>
    <Modal link="quellen" title="Quellen">
      sadfasd
    </Modal>
    <Modal link="impressum" title="Impressum">
      <div class="impressum-content">
        <div class="headline-modal">Anzeigen und Marketing<br />Kleine Zeitung GmbH &amp; Co KG</div>
        <p>
          Informationen der<br />
          <a href="https://www.kleinezeitung.at/service/unternehmen/4698165/Anzeigen-und-Marketing-Kleine-Zeitung-GmbH-Co-KG" target="_blank">Anzeigen und Marketing Kleine Zeitung GmbH &amp; Co KG</a>
        </p>
        <p>
          Impressum gem. § 5 ECG, § 14 UGB, § 24 Mediengesetz<br />
          <a href="https://www.kleinezeitung.at/service/unternehmen/4735398/Offenlegung-laut-25-Mediengesetz_Offenlegung-fuer-die-Anzeigen" target="_blank">Offenlegung gemäß § 25 Mediengesetz</a>
        </p>
        <p>Gadollaplatz 1, 8010 Graz</p>
      </div>
    </Modal>
    <Modal link="kontakt" title="Kontakt">
      sadfasd
    </Modal>
  </footer>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import ModalButton from '@/components/util/ModalButton.vue';
import Modal from '@/components/util/Modal.vue';

export default defineComponent({
  name: 'Footer',
  components: {
    ModalButton,
    Modal,
  },
});
</script>
