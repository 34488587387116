<style lang="scss">
@import '~bootstrap';

#app {
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  padding-top: 5px;
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 5px;
    background: #eee;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  }
}

// .page-grid {
//   display: grid;
//   grid-template-areas:
//     'menu header header'
//     'menu content .';
//   grid-template-rows: max-content;

//   .wrapper-content {
//     margin-left: 15px;
//     grid-area: content;
//   }

//   .wrapper-nav {
//     grid-area: menu;
//   }

//   .wrapper-header {
//     grid-area: header;
//   }
// }

.navigation-left {
  width: 350px;
  height: 100vh;
  position: fixed;
  z-index: 10;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #ccc #eee;
  border-right: 1px solid #eee;
  background: #eee;
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    background: #eee;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 20px;
    border: 4px solid #eee;
  }
  @media (max-width: 1200px) {
    left: -350px;
  }
  .navi-slogan-img {
    width: 100%;
    padding: 30px 20px;
    z-index: 10;
    img {
      display: block;
      opacity: 0.2;
      max-width: 80%;
    }
  }
}

.content-container {
  position: relative;
  background: #f9f9f9;
  margin-left: 350px;
  @media (max-width: 1200px) {
    margin-left: 0px;
  }
  @media (max-width: 768px) {
    background: #fff;
  }
  overflow: hidden;

  .wrapper {
    //max-width: $width-wrapper;

    .wrapper-content {
      margin: 0 auto;
      background: #fff;
      padding: 40px;
      margin: 0 auto;
      @media (max-width: 768px) {
        padding: 30px;
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }
  }
}

#backToTop {
  text-align: center;
  z-index: 100;
  opacity: 1;
  /*@media(max-width:992px) {
      position:fixed;
      right:10px;
      bottom:10px;
    }*/
  position: fixed;
  right: 5px;
  bottom: 5px;
  background: #f9f9f9;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  @media (max-width: 992px) {
    right: 0;
    bottom: 0px;
  }

  a {
    display: block;
    text-align: center;
    margin: 0;
    outline: none;
    span {
      color: #999;
      padding: 0;
      margin: 0;
      display: block;
      position: relative;
      left: 0px;
      width: 50px;
      height: 50px;
      line-height: 50px;
      top: 0px;
      font-size: 200%;
      -webkit-transition: all 500ms ease;
      -moz-transition: all 500ms ease;
      -ms-transition: all 500ms ease;
      -o-transition: all 500ms ease;
      transition: all 500ms ease;
      @media (max-width: 992px) {
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 200%;
        background-color: rgba($color: #fff, $alpha: 0.9);
      }
    }
  }
  &:hover {
    bottom: 10px;
    span {
      color: $font-color;
      @media (max-width: 992px) {
        top: 0;
      }
    }
  }
}

.pagetransition-enter-active {
  transition: all 1000ms ease;
}
.pagetransition-leave-active {
  transition: all 50ms ease;
}

.pagetransition-leave-active {
  position: relative;
  background: #fff;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.pagetransition-leave-to {
  top: 0px;
  opacity: 0;
}

.pagetransition-enter-from {
  opacity: 0;
}
</style>

<template>
  <a name="top" id="top"></a>
  <div id="backToTop">
    <a href="#top" v-smooth-scroll><span class="material-icons">arrow_upward</span></a>
  </div>
  <div class="navigation-left">
    <NavContainer></NavContainer>
    <div class="navi-slogan-img"><img src="@/assets/img/generel/slogan-navi.svg" alt="Kleine Zeitung" /></div>
  </div>
  <div class="content-container">
    <div class="wrapper">
      <div class="row">
        <div class="col-xl-12 col-md-12 ">
          <Header></Header>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 col-md-12 wrapper-content">
          <router-view v-slot="{ Component }">
            <transition name="pagetransition">
              <component :is="Component" />
            </transition>
          </router-view>
        </div>
      </div>
    </div>
  </div>
  <Footer></Footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useStore } from 'vuex';

import NavContainer from '@/components/navigation/NavContainer.vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default defineComponent({
  components: {
    NavContainer,
    Header,
    Footer,
  },
  setup() {
    const store = useStore();

    window.addEventListener('resize', () => {
      store.state.windowsize = window.innerWidth;
    });
    document.addEventListener('scroll', e => {
      const totop = document.getElementById('backToTop');
      if (!totop) return;
      if (document.documentElement.scrollTop > 300) {
        totop.style.opacity = '1';
      } else {
        totop.style.opacity = '0';
      }
    });
  },
});
</script>
