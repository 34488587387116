
import { computed, defineComponent, ref, watch, watchEffect } from 'vue';
import { useStore } from 'vuex';
//import NavLink from '@/components/navigation/NavLink.vue';
import Search from '@/components/Search.vue';
//import DropDown from '@/components/util/DropDown.vue';

export default defineComponent({
  components: {
    //NavLink,
    Search,
    //DropDown,
  },
  setup() {
    const store = useStore();
    const width = computed(() => store.state.windowsize);
    const showLogo = computed(() => width.value < 1080);
    const showSearch = computed(() => store.state.searchstate);

    const menu = computed(() => store.state.menustate);

    const toggleMenu = () => {
      store.state.menustate = !store.state.menustate;
    };

    const toggleSearch = () => {
      store.state.searchstate = !store.state.searchstate;
    };

    return {
      menu,
      toggleMenu,
      toggleSearch,
      showSearch,
      showLogo,
    };
  },
});
