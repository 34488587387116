const state = {
  produkte: {
    Beilage: {
      'Rota 42.5g': {
        type: 'paged',
        bild: 'produkt-beilage.png',
        kategoriename: 'Beilage',
        verarbeitung: 'beilagen',
        items: [
          {
            seiten: 12,
            gewicht: 17,
            grundpreis: 1269.48525,
            weiteretausend: 36.40725,
          },
          {
            seiten: 16,
            gewicht: 23,
            grundpreis: 1311.893,
            weiteretausend: 41.437,
          },
          {
            seiten: 20,
            gewicht: 29,
            grundpreis: 1547.08125,
            weiteretausend: 46.48325,
          },
          {
            seiten: 24,
            gewicht: 34,
            grundpreis: 1589.412,
            weiteretausend: 51.502,
          },
          {
            seiten: 28,
            gewicht: 40,
            grundpreis: 1995.0535,
            weiteretausend: 56.5455,
          },
          {
            seiten: 32,
            gewicht: 46,
            grundpreis: 1674.255,
            weiteretausend: 61.567,
          },
          {
            seiten: 36,
            gewicht: 52,
            grundpreis: 2376.891,
            weiteretausend: 72.677,
          },
          {
            seiten: 40,
            gewicht: 57,
            grundpreis: 1928.5695,
            weiteretausend: 77.6655,
          },
          {
            seiten: 44,
            gewicht: 63,
            grundpreis: 2737.09975,
            weiteretausend: 82.76675,
          },
          {
            seiten: 48,
            gewicht: 69,
            grundpreis: 2048.03225,
            weiteretausend: 87.79925,
          },
          {
            seiten: 56,
            gewicht: 80,
            grundpreis: 2278.001,
            weiteretausend: 113.135,
          },
          {
            seiten: 64,
            gewicht: 92,
            grundpreis: 2448.9575,
            weiteretausend: 123.2385,
          },
        ],
      },
      'Rota 55g': {
        type: 'paged',
        bild: 'produkt-beilage.png',
        kategoriename: 'Beilage',
        verarbeitung: 'beilagen',
        items: [
          {
            seiten: 8,
            gewicht: 15,
            grundpreis: 1258.983,
            weiteretausend: 37.7564,
          },
          {
            seiten: 12,
            gewicht: 22,
            grundpreis: 1317.4975,
            weiteretausend: 46.0097,
          },
          {
            seiten: 16,
            gewicht: 30,
            grundpreis: 1375.847,
            weiteretausend: 54.2278,
          },
          {
            seiten: 20,
            gewicht: 37,
            grundpreis: 1627.0705,
            weiteretausend: 62.4811,
          },
          {
            seiten: 24,
            gewicht: 45,
            grundpreis: 1685.343,
            weiteretausend: 70.6882,
          },
          {
            seiten: 28,
            gewicht: 52,
            grundpreis: 2106.973,
            weiteretausend: 78.9294,
          },
          {
            seiten: 32,
            gewicht: 59,
            grundpreis: 1802.163,
            weiteretausend: 87.1486,
          },
          {
            seiten: 36,
            gewicht: 67,
            grundpreis: 2520.881,
            weiteretausend: 101.475,
          },
          {
            seiten: 40,
            gewicht: 74,
            grundpreis: 2088.361,
            weiteretausend: 109.6238,
          },
          {
            seiten: 44,
            gewicht: 82,
            grundpreis: 2913.1135,
            weiteretausend: 117.9695,
          },
          {
            seiten: 48,
            gewicht: 89,
            grundpreis: 2240.0345,
            weiteretausend: 126.1997,
          },
          {
            seiten: 56,
            gewicht: 104,
            grundpreis: 2502.027,
            weiteretausend: 157.9402,
          },
          {
            seiten: 64,
            gewicht: 119,
            grundpreis: 2705.054,
            weiteretausend: 174.4578,
          },
        ],
      },
      'Rota 80.0g': {
        type: 'paged',
        bild: 'produkt-beilage.png',
        kategoriename: 'Beilage',
        verarbeitung: 'beilagen',
        items: [
          {
            seiten: 4,
            gewicht: 11,
            grundpreis: 1459.44477380952,
            weiteretausend: 42.0701547619048,
          },
          {
            seiten: 8,
            gewicht: 22,
            grundpreis: 1564.05739285714,
            weiteretausend: 58.9006785714285,
          },
          {
            seiten: 12,
            gewicht: 32,
            grundpreis: 1668.58816666667,
            weiteretausend: 75.7148333333334,
          },
          {
            seiten: 16,
            gewicht: 43,
            grundpreis: 1773.28263095238,
            weiteretausend: 92.5617261904762,
          },
          {
            seiten: 20,
            gewicht: 54,
            grundpreis: 2106.38240476191,
            weiteretausend: 109.364880952381,
          },
          {
            seiten: 24,
            gewicht: 65,
            grundpreis: 2210.91317857143,
            weiteretausend: 126.179035714286,
          },
          {
            seiten: 32,
            gewicht: 86,
            grundpreis: 2420.05657142857,
            weiteretausend: 159.823714285714,
          },
          {
            seiten: 40,
            gewicht: 108,
            grundpreis: 3399.14496428571,
            weiteretausend: 200.640392857143,
          },
          {
            seiten: 48,
            gewicht: 130,
            grundpreis: 3630.31520238095,
            weiteretausend: 238.690440476191,
          },
        ],
      },
      'LWC glänzend': {
        type: 'paged',
        bild: 'produkt-beilage.png',
        kategoriename: 'Beilage',
        verarbeitung: 'beilagen',
        items: [
          {
            seiten: 8,
            gewicht: 17,
            grundpreis: 682.57475,
            weiteretausend: 19.80495,
          },
          {
            seiten: 12,
            gewicht: 26,
            grundpreis: 742.01875,
            weiteretausend: 30.48375,
          },
          {
            seiten: 16,
            gewicht: 35,
            grundpreis: 774.51275,
            weiteretausend: 38.19255,
          },
          {
            seiten: 20,
            gewicht: 44,
            grundpreis: 1700.47075,
            weiteretausend: 47.67015,
          },
          {
            seiten: 24,
            gewicht: 52,
            grundpreis: 1834.8605,
            weiteretausend: 55.4081,
          },
          {
            seiten: 28,
            gewicht: 61,
            grundpreis: 2163.876,
            weiteretausend: 64.1212,
          },
          {
            seiten: 32,
            gewicht: 70,
            grundpreis: 2306.689,
            weiteretausend: 71.6298,
          },
          {
            seiten: 40,
            gewicht: 87,
            grundpreis: 2075.21325,
            weiteretausend: 94.65665,
          },
          {
            seiten: 48,
            gewicht: 105,
            grundpreis: 2105.71625,
            weiteretausend: 109.57925,
          },
          {
            seiten: 56,
            gewicht: 122,
            grundpreis: 2499.0295,
            weiteretausend: 130.5139,
          },
          {
            seiten: 64,
            gewicht: 140,
            grundpreis: 2685.507,
            weiteretausend: 146.1174,
          },
        ],
      },
      'LWC matt': {
        type: 'paged',
        bild: 'produkt-beilage.png',
        kategoriename: 'Beilage',
        verarbeitung: 'beilagen',
        items: [
          {
            seiten: 8,
            gewicht: 16,
            grundpreis: 697.705,
            weiteretausend: 19.6604,
          },
          {
            seiten: 12,
            gewicht: 24,
            grundpreis: 757.283625,
            weiteretausend: 30.044325,
          },
          {
            seiten: 16,
            gewicht: 32,
            grundpreis: 788.13525,
            weiteretausend: 37.74645,
          },
          {
            seiten: 20,
            gewicht: 41,
            grundpreis: 1729.52525,
            weiteretausend: 47.12585,
          },
          {
            seiten: 24,
            gewicht: 49,
            grundpreis: 1859.84325,
            weiteretausend: 54.64685,
          },
          {
            seiten: 28,
            gewicht: 57,
            grundpreis: 2187.69675,
            weiteretausend: 63.20055,
          },
          {
            seiten: 32,
            gewicht: 65,
            grundpreis: 2326.22,
            weiteretausend: 70.495,
          },
          {
            seiten: 40,
            gewicht: 81,
            grundpreis: 2117.587,
            weiteretausend: 934444,
          },
          {
            seiten: 48,
            gewicht: 97,
            grundpreis: 2126.281,
            weiteretausend: 107.9344,
          },
          {
            seiten: 56,
            gewicht: 114,
            grundpreis: 2513.023125,
            weiteretausend: 127.598825,
          },
          {
            seiten: 64,
            gewicht: 130,
            grundpreis: 2699.443875,
            weiteretausend: 143.805775,
          },
        ],
      },
    },
    Mantel: {
      'Rota 42.5g': {
        type: 'noprint',
        bild: 'produkt-mantel.png',
        kategoriename: 'Mantel',
        verarbeitung: 'bundesland',
      },
      'Rota 80g': {
        type: 'paged',
        bild: 'produkt-mantel.png',
        kategoriename: 'Mantel',
        verarbeitung: 'mantel',
        items: [
          {
            seiten: 4,
            gewicht: 11,
            grundpreis: 1488.86122334406,
            weiteretausend: 42.9181173615675,
          },
          {
            seiten: 8,
            gewicht: 22,
            grundpreis: 1595.58240578792,
            weiteretausend: 60.0878758329074,
          },
          {
            seiten: 12,
            gewicht: 32,
            grundpreis: 1702.22009332776,
            weiteretausend: 77.240935323443,
          },
        ],
      },
      LWC: {
        type: 'single',
        bild: 'produkt-mantel.png',
        kategoriename: 'Mantel',
        verarbeitung: 'mantel',
        items: [
          {
            menge: 5000,
            grundpreis: 600.3,
            weiteretausend: 46.98,
          },
          {
            menge: 10000,
            grundpreis: 836.05,
            weiteretausend: 45.63,
          },
          {
            menge: 20000,
            grundpreis: 1293.75,
            weiteretausend: 41.85,
          },
          {
            menge: 30000,
            grundpreis: 1712.35,
            weiteretausend: 41.85,
          },
          {
            menge: 40000,
            grundpreis: 2132.1,
            weiteretausend: 41.85,
          },
          {
            menge: 50000,
            grundpreis: 2551.85,
            weiteretausend: 41.85,
          },
          {
            menge: 100000,
            grundpreis: 4648.3,
            weiteretausend: 41.85,
          },
          {
            menge: 200000,
            grundpreis: 8842.35,
            weiteretausend: 41.85,
          },
          {
            menge: 300000,
            grundpreis: 13036.4,
            weiteretausend: 37.2,
          },
        ],
      },
    },
    'Tip on Card': {
      none: {
        bild: 'produkt-tiponcard.png',
        kategoriename: 'Tip on Card',
        type: 'single',
        verarbeitung: 'tipon',
        items: [
          {
            menge: 5000,
            grundpreis: 231.4,
            weiteretausend: 9.1,
          },
          {
            menge: 10000,
            grundpreis: 276.9,
            weiteretausend: 9.1,
          },
          {
            menge: 20000,
            grundpreis: 369.2,
            weiteretausend: 9.1,
          },
          {
            menge: 30000,
            grundpreis: 461.5,
            weiteretausend: 9.1,
          },
          {
            menge: 40000,
            grundpreis: 552.5,
            weiteretausend: 9.1,
          },
          {
            menge: 50000,
            grundpreis: 640.9,
            weiteretausend: 8.97,
          },
          {
            menge: 100000,
            grundpreis: 1041,
            weiteretausend: 5.72,
          },
          {
            menge: 200000,
            grundpreis: 1575.6,
            weiteretausend: 5.72,
          },
          {
            menge: 300000,
            grundpreis: 2250.3,
            weiteretausend: 5.72,
          },
          {
            menge: 400000,
            grundpreis: 2822.3,
            weiteretausend: 5.72,
          },
        ],
      },
    },
    Flyer: {
      none: {
        type: 'single',
        bild: 'produkt-flyer.png',
        kategoriename: 'Flyer',
        verarbeitung: 'flyer',
        items: [
          {
            menge: 5000,
            grundpreis: 314.4,
            weiteretausend: 26.268,
          },
          {
            menge: 10000,
            grundpreis: 441.6,
            weiteretausend: 23.964,
          },
          {
            menge: 20000,
            grundpreis: 669.6,
            weiteretausend: 21.624,
          },
          {
            menge: 30000,
            grundpreis: 882,
            weiteretausend: 20.472,
          },
          {
            menge: 40000,
            grundpreis: 1089.6,
            weiteretausend: 19.752,
          },
          {
            menge: 50000,
            grundpreis: 1318.8,
            weiteretausend: 19.692,
          },
          {
            menge: 100000,
            grundpreis: 2018.4,
            weiteretausend: 15.6,
          },
          {
            menge: 200000,
            grundpreis: 3838.8,
            weiteretausend: 15.6,
          },
          {
            menge: 300000,
            grundpreis: 5655.6,
            weiteretausend: 156,
          },
        ],
      },
    },
    Folder: {
      none: {
        type: 'single',
        bild: 'produkt-folder.png',
        kategoriename: 'Folder',
        verarbeitung: 'folder',
        items: [
          {
            menge: 5000,
            grundpreis: 487.5,
            weiteretausend: 46.28,
          },
          {
            menge: 10000,
            grundpreis: 718.9,
            weiteretausend: 46.28,
          },
          {
            menge: 20000,
            grundpreis: 1142.7,
            weiteretausend: 46.28,
          },
          {
            menge: 30000,
            grundpreis: 1618.5,
            weiteretausend: 46.28,
          },
          {
            menge: 40000,
            grundpreis: 2099.5,
            weiteretausend: 40.898,
          },
          {
            menge: 50000,
            grundpreis: 2133,
            weiteretausend: 21.554,
          },
          {
            menge: 100000,
            grundpreis: 3195.45,
            weiteretausend: 21.554,
          },
          {
            menge: 200000,
            grundpreis: 5305,
            weiteretausend: 21.554,
          },
          {
            menge: 300000,
            grundpreis: 7342.8,
            weiteretausend: 21.554,
          },
        ],
      },
    },
  },
  verarbeitung: {
    beilagen: {
      wochentag: [
        {
          grammatur: 0,
          preisunter80k: 118.8,
          preisab80k: 117,
        },
        {
          grammatur: 20,
          preisunter80k: 124.65,
          preisab80k: 125.8,
        },
        {
          grammatur: 40,
          preisunter80k: 139.35,
          preisab80k: 137.4,
        },
        {
          grammatur: 60,
          preisunter80k: 151.3,
          preisab80k: 149.05,
        },
        {
          grammatur: 80,
          preisunter80k: 172,
          preisab80k: 169.7,
        },
        {
          grammatur: 100,
          preisunter80k: 185.3,
          preisab80k: 183,
        },
        {
          grammatur: 120,
          preisunter80k: 203,
          preisab80k: 200.7,
        },
        {
          grammatur: 140,
          preisunter80k: 220.68,
          preisab80k: 218.38,
        },
        {
          grammatur: 160,
          preisunter80k: 238.38,
          preisab80k: 236.08,
        },
        {
          grammatur: 180,
          preisunter80k: 256.08,
          preisab80k: 253.78,
        },
      ],
      sonntag: [
        {
          grammatur: 0,
          preisunter80k: 123.9,
          preisab80k: 122.1,
        },
        {
          grammatur: 20,
          preisunter80k: 133.15,
          preisab80k: 132.2,
        },
        {
          grammatur: 40,
          preisunter80k: 145.4,
          preisab80k: 143.25,
        },
        {
          grammatur: 60,
          preisunter80k: 157.7,
          preisab80k: 155.35,
        },
        {
          grammatur: 80,
          preisunter80k: 179.2,
          preisab80k: 176.1,
        },
        {
          grammatur: 100,
          preisunter80k: 193,
          preisab80k: 189.9,
        },
        {
          grammatur: 120,
          preisunter80k: 211.4,
          preisab80k: 207.9,
        },
        {
          grammatur: 140,
          preisunter80k: 229.8,
          preisab80k: 225.9,
        },
        {
          grammatur: 160,
          preisunter80k: 248.2,
          preisab80k: 254.9,
        },
        {
          grammatur: 180,
          preisunter80k: 266.6,
          preisab80k: 262.9,
        },
      ],
    },
    mantel: {
      wochentag: [
        {
          grammatur: 0,
          preisunter80k: 415.8,
          preisab80k: 409.5,
        },
        {
          grammatur: 20,
          preisunter80k: 436.45,
          preisab80k: 440.3,
        },
        {
          grammatur: 40,
          preisunter80k: 457.1,
          preisab80k: 450.45,
        },
      ],
      sonntag: [
        {
          grammatur: 0,
          preisunter80k: 433.65,
          preisab80k: 427.35,
        },
        {
          grammatur: 20,
          preisunter80k: 455.35,
          preisab80k: 459.2,
        },
        {
          grammatur: 40,
          preisunter80k: 476.7,
          preisab80k: 469.7,
        },
      ],
    },
    tipon: {
      perforation: {
        keine: 0,
        '1x': 400,
        '2x': 500,
      },
      titelseite: {
        wochentag: {
          preisunter80k: 166.32,
          preisab80k: 163.8,
        },
        sonntag: {
          preisunter80k: 173.46,
          preisab80k: 170.94,
        },
      },
      u4: {
        wochentag: {
          preisunter80k: 130.68,
          preisab80k: 128.7,
        },
        sonntag: {
          preisunter80k: 136.29,
          preisab80k: 134.31,
        },
      },
    },
    flyer: {
      wochentag: [
        {
          grammatur: 20,
          preisunter80k: 118.8,
          preisab80k: 117,
        },
      ],
      sonntag: [
        {
          grammatur: 20,
          preisunter80k: 123.9,
          preisab80k: 122.1,
        },
      ],
    },
    folder: {
      wochentag: [
        {
          grammatur: 20,
          preisunter80k: 118.8,
          preisab80k: 117,
        },
      ],
      sonntag: [
        {
          grammatur: 20,
          preisunter80k: 123.9,
          preisab80k: 122.1,
        },
      ],
    },
    bundesland: {
      zweiseitig: {
        kombi: {
          wochentag: 102370.0,
          sonntag: 107488.5,
        },
        steiermark: {
          wochentag: 69611.6,
          sonntag: 73092.18,
        },
        kaernten: {
          wochentag: 34805.8,
          sonntag: 36546.09,
        },
      },
      vierseitig: {
        kombi: {
          wochentag: 115160.7,
          sonntag: 120918.74,
        },
        steiermark: {
          wochentag: 78309.28,
          sonntag: 82224.74,
        },
        kaernten: {
          wochentag: 39154.64,
          sonntag: 41112.37,
        },
      },
    },
  },
};

const getters = {};
const mutations = {};
const actions = {};

export default {
  state,
  getters,
  mutations,
  actions,
};
