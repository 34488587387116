import { RouteRecordRaw } from 'vue-router';
//import Home from '../views/Home.vue';
import DynamicPage from '../components/DynamicPage.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/test',
    name: 'Test',
    component: () => import(/* webpackChunkName: "Startseite" */ '../views/Test.vue'),
    meta: { pageid: 1 },
  },
  {
    path: '/',
    name: 'Startseite',
    component: () => import(/* webpackChunkName: "Startseite" */ '../views/Startseite.vue'),
  },
  {
    path: '/allgemeines/allgemeine-informationen',
    name: 'Allgemeine Informationen',
    component: () => import(/* webpackChunkName: "Allgemeines" */ '../views/allgemeines/AllgemeineInformationen.vue'),
  },
  {
    path: '/allgemeines/technische-daten',
    name: 'Technische Daten',
    component: () => import(/* webpackChunkName: "Allgemeines" */ '../views/allgemeines/TechnischeDaten.vue'),
  },

  {
    path: '/digital/programmatic-advertising',
    name: 'Programmatic Advertising',
    component: () => import(/* webpackChunkName: "Programmatic Advertising" */ '../views/digital/ProgrammaticAdvertising.vue'),
  },
  {
    path: '/digital/e-paper',
    name: 'EPaper',
    component: () => import(/* webpackChunkName: "EPaper" */ '../views/digital/EPaper.vue'),
  },
  {
    path: '/prprodukte/content-teaser',
    name: 'ContentTeaserPerformancePaket',
    component: () => import(/* webpackChunkName: "ContentTeaserPerformancePaket" */ '../views/prprodukte/ContentTeaserPerformancePaket.vue'),
  },
  {
    path: '/digital/calender-ad',
    name: 'CalenderAd',
    component: () => import(/* webpackChunkName: "CalenderAd" */ '../views/digital/CalenderAd.vue'),
  },
  {
    path: '/digital/kleine-extra',
    name: 'KleineExtra',
    component: () => import(/* webpackChunkName: "KleineExtra" */ '../views/digital/KleineExtra.vue'),
  },
  {
    path: '/digital/erweiterung-digitales-prospekt',
    name: 'ErweiterungDigitalesProspekt',
    component: () => import(/* webpackChunkName: "ErweiterungDigitalesProspekt" */ '../views/digital/ErweiterungDigitalesProspekt.vue'),
  },
  {
    path: '/digital/job-portfolio',
    name: 'JobPortfolio',
    component: () => import(/* webpackChunkName: "JobPortfolio" */ '../views/digital/JobPortfolio.vue'),
  },
  {
    path: '/digital/displaywerbung',
    name: 'Displaywerbung',
    component: () => import(/* webpackChunkName: "Displaywerbung" */ '../views/digital/Displaywerbung.vue'),
  },
  {
    path: '/digital/newsletter',
    name: 'Newsletter',
    component: () => import(/* webpackChunkName: "Newsletter" */ '../views/digital/Newsletter.vue'),
  },
  {
    path: '/digital/gutgemacht',
    name: 'GutGemacht',
    component: () => import(/* webpackChunkName: "GutGemacht" */ '../views/digital/GutGemacht.vue'),
  },
  {
    path: '/beilagensonderwerbeformen/werbebeilagen',
    name: 'Werbebeilagen',
    component: () => import(/* webpackChunkName: "Werbebeilagen" */ '../views/beilagensonderwerbeformen/Werbebeilagen.vue'),
  },
  {
    path: '/beilagensonderwerbeformen/digitales-prospekt',
    name: 'Digitales Prospekt',
    component: () => import(/* webpackChunkName: "DigitalesProspekt" */ '../views/beilagensonderwerbeformen/DigitalesProspekt.vue'),
  },
  {
    path: '/beilagensonderwerbeformen/druckstrecke',
    name: 'Druckstrecke',
    component: () => import(/* webpackChunkName: "Druckstrecke" */ '../views/beilagensonderwerbeformen/Druckstrecke.vue'),
  },
  {
    path: '/beilagensonderwerbeformen/mantel',
    name: 'Mantel',
    component: () => import(/* webpackChunkName: "Mantel" */ '../views/beilagensonderwerbeformen/Mantel.vue'),
  },
  {
    path: '/beilagensonderwerbeformen/poster',
    name: 'Poster',
    component: () => import(/* webpackChunkName: "Poster" */ '../views/beilagensonderwerbeformen/Poster.vue'),
  },
  {
    path: '/beilagensonderwerbeformen/tiponcard',
    name: 'Tiponcard',
    component: () => import(/* webpackChunkName: "Tiponcard" */ '../views/beilagensonderwerbeformen/Tiponcard.vue'),
  },
  {
    path: '/beilagensonderwerbeformen/memostick',
    name: 'Memostick',
    component: () => import(/* webpackChunkName: "Memostick" */ '../views/beilagensonderwerbeformen/Memostick.vue'),
  },
  {
    path: '/beilagensonderwerbeformen/spezifikationen',
    name: 'Spezifikationen',
    component: () => import(/* webpackChunkName: "Spezifikationen" */ '../views/beilagensonderwerbeformen/Spezifikationen.vue'),
  },
  {
    path: '/marktplaetze/marktplaetze',
    name: 'Marktplaetze',
    component: () => import(/* webpackChunkName: "Marktplaetze" */ '../views/marktplaetze/Marktplaetze.vue'),
  },
  {
    path: '/marktplaetze/bildung-beruf',
    name: 'Bildung Beruf',
    component: () => import(/* webpackChunkName: "BildungBeruf" */ '../views/marktplaetze/BildungBeruf.vue'),
  },
  {
    path: '/marktplaetze/wohnen',
    name: 'Wohnen',
    component: () => import(/* webpackChunkName: "Wohnen" */ '../views/marktplaetze/Wohnen.vue'),
  },
  {
    path: '/marktplaetze/VeranstaltungenKulinarik',
    name: 'VeranstaltungenKulinarik',
    component: () => import(/* webpackChunkName: "VeranstaltungenKulinarik" */ '../views/marktplaetze/VeranstaltungenKulinarik.vue'),
  },
  {
    path: '/marktplaetze/moblitaet',
    name: 'Mobilitaet',
    component: () => import(/* webpackChunkName: "Mobilitaet" */ '../views/marktplaetze/Mobilitaet.vue'),
  },
  {
    path: '/hauptblatt/regionen',
    name: 'Regionen',
    component: () => import(/* webpackChunkName: "Regionen" */ '../views/hauptblatt/Regionen.vue'),
  },
  {
    path: '/hauptblatt/exklusivplatzierung',
    name: 'Exklusivplatzierung',
    component: () => import(/* webpackChunkName: "Exklusivplatzierung" */ '../views/hauptblatt/Exklusivplatzierung.vue'),
  },
  {
    path: '/hauptblatt/topplatzierung-politik',
    name: 'Topplatzierung Politik',
    component: () => import(/* webpackChunkName: "Hauptblatt" */ '../views/hauptblatt/TopplatzierungPolitik.vue'),
  },
  {
    path: '/hauptblatt/themenplatzierung',
    name: 'Themenplatzierung',
    component: () => import(/* webpackChunkName: "Hauptblatt" */ '../views/hauptblatt/Themenplatzierung.vue'),
  },
  {
    path: '/hauptblatt/basisplatzierung',
    name: 'Basisplatzierung',
    component: () => import(/* webpackChunkName: "Hauptblatt" */ '../views/hauptblatt/Basisplatzierung.vue'),
  },

  {
    path: '/sonderprodukte/themenschwerpunkte',
    name: 'Themenschwerpunkte',
    component: () => import(/* webpackChunkName: "Themenschwerpunkte" */ '../views/sonderprodukte/Themenschwerpunkte.vue'),
  },
  {
    path: '/prprodukte/prproduktdigital',
    name: 'PRProduktDigital',
    component: () => import(/* webpackChunkName: "PRProduktDigital" */ '../views/prprodukte/PRProduktDigital.vue'),
  },
  {
    path: '/prprodukte/prproduktprint',
    name: 'PRProduktPrint',
    component: () => import(/* webpackChunkName: "PRProduktPrint" */ '../views/prprodukte/PRProduktPrint.vue'),
  },
  {
    path: '/sonderprodukte/kinderzeitung',
    name: 'Kinderzeitung',
    component: () => import(/* webpackChunkName: "Kinderzeitung" */ '../views/sonderprodukte/Kinderzeitung.vue'),
  },
  {
    path: '/sonderprodukte/pausenzeitung',
    name: 'Pausenzeitung',
    component: () => import(/* webpackChunkName: "Pausenzeitung" */ '../views/sonderprodukte/Pausenzeitung.vue'),
  },
  {
    path: '/sonderprodukte/futter',
    name: 'Futter',
    component: () => import(/* webpackChunkName: "Futter" */ '../views/sonderprodukte/Futter.vue'),
  },
  {
    path: '/calculator',
    name: 'calculator',
    component: () => import(/* webpackChunkName: "about" */ '../views/Calculator.vue'),
  },
  {
    path: '/zielgruppenrechner',
    name: 'zielgruppenrechner',
    component: () => import(/* webpackChunkName: "zielgruppenrechner" */ '../views/Zielgruppenrechner.vue'),
  },
  {
    path: '/kontakte',
    name: 'Kontakte',
    component: () => import(/* webpackChunkName: "about" */ '../views/Kontakte.vue'),
  },
];

export default routes;
