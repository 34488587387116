
import { computed, defineComponent, ref, watchEffect } from 'vue';
import NavLink from '@/components/navigation/NavLink.vue';
import { useStore } from 'vuex';
import { RouteRecordRaw, useRouter } from 'vue-router';
import axios from 'axios';
import NavAccordion from '@/components/navigation/NavAccordion.vue';

export default defineComponent({
  components: {
    NavLink,
    NavAccordion,
  },
  setup() {
    const store = useStore();
    const width = computed(() => store.state.windowsize);
    const menu = computed(() => store.state.menustate);
    const showLogo = computed(() => width.value > 1400);

    const items = computed(() => {
      return store.state.menu.items.hierarchical;
    });

    const toggleMenu = () => {
      store.state.menustate = !store.state.menustate;
    };

    watchEffect(() => {
      if (width.value > 1400) {
        store.state.menustate = true;
      } else {
        store.state.menustate = false;
      }
    });

    return { items, menu, toggleMenu, showLogo };
  },
});
