<template>
  <router-link v-bind="props" v-slot="{ navigate }" @click="navigateHook(navigate)" class="link">
    <slot></slot>
  </router-link>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  setup(props) {
    const store = useStore();
    const navigateHook = (navigate: Function) => {
      store.state.menustate = false;
      Object.keys(store.state.dropdownstate).forEach(link => {
        store.state.dropdownstate[link] = false;
      });
      navigate();
    };

    return { props, navigateHook };
  },
});
</script>

<style lang="scss">
.link {
  display: block;
  text-align: left;
  padding: 15px 15px;
  position: relative;
  border-bottom: 1px solid #eee;

  position: relative;
  @media (max-width: 400px) {
    font-size: 90%;
  }
  &:last-child {
    border-bottom: 0;
  }
  &:hover {
    color: #888;
    background: $blue-darker;
    color: #fff !important;
    padding-left: 25px;
    @media (max-width: 768px) {
      padding-left: 20px;
    }
  }
}
.router-link-exact-active {
  //border-right: 5px solid $blue-darker;
  &:after {
    content: '';
    display: block;
    width: 8px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    background: $blue-darker;
  }
}
</style>
