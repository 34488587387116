<style lang="scss">
.smd-modal-container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;

  .smd-modal {
    $border-radius: 0px;

    background-color: #fff;
    border-radius: $border-radius;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.5);
    width: 768px;
    max-height: 80vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #ccc #eee;
    &::-webkit-scrollbar {
      width: 12px;
    }
    &::-webkit-scrollbar-track {
      background: #eee;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #ccc;
      border-radius: 20px;
      border: 3px solid #eee;
    }
    @media (max-width: 992px) {
      max-height: 90vh;
      width: 90%;
      margin-left: 4%;
      left: 0;
      transform: translate(0%, -50%);
    }
    .smd-modal-header {
      width: 100%;
      .title {
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
        font-size: 150%;
        width: 100%;
        padding: 15px 35px 15px 15px;
        color: $font-color;
        border-bottom: 1px solid #eee;
        position: relative;
      }
      .closebutton {
        position: absolute;
        right: 13px;
        top: 28px;
        cursor: pointer;
        z-index: 100;
        span {
          color: $font-color;
          font-size: 200%;
          position: relative;

          -webkit-transition: all 800ms ease;
          -moz-transition: all 800ms ease;
          -ms-transition: all 800ms ease;
          -o-transition: all 800ms ease;
          transition: all 800ms ease;
        }
        &:hover {
          span {
            transform: rotate(360deg);
          }
        }
      }
    }

    .content {
      padding: 15px;
    }
  }
}
</style>

<template>
  <teleport to="body">
    <div class="smd-modal-container" :class="{ open: isVisible }" v-if="isVisible" @click.self="hideModal">
      <div class="smd-modal">
        <div class="smd-modal-header">
          <div class="title" v-if="title" v-html="title"></div>
          <div class="closebutton" v-if="!noclose" @click="hideModal"><span class="material-icons">close</span></div>
        </div>
        <div class="content">
          <slot></slot>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
  props: {
    link: {
      type: String,
      required: true,
    },
    title: String,
    noclose: Boolean,
  },
  setup(props) {
    const store = useStore();

    const isVisible = computed(() => store.state.modalstate[props.link]);
    const hideModal = () => (store.state.modalstate[props.link] = false);

    return {
      props,
      isVisible,
      hideModal,
    };
  },
});
</script>
